import { Button } from "../Utils/Button";
import { Link, useParams } from "react-router-dom";

// Assets
import AdsClickIcon from "@mui/icons-material/AdsClick";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import bannerImg from "../../assets/singleproduct.jpeg";
import { data } from "../../data/data";

// Styles
import "./styles.css";

// Hooks
import { useEffect, useState } from "react";

// MUI Icons
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { api } from "../../service/api";
import { Head } from "../Utils/Head";

interface Data {
  _id: string;
  type: string;
  description: string;
  description1: string;
  description2: string;
  description3: string;
  title: string;
  meta_tag_description: string;
  url: string;
  image_url: string;
}

export const SinglePageProduct = () => {
  const { id } = useParams();

  const [filteredData, setFilteredData] = useState<Data>();

  const [active, setActive] = useState(false);
  const [name, setName] = useState("");
  const link = `https://wa.me/5562992693610?text=Olá,%20me%20chamo%20${name}%20e%20tenho%20interesse%20no%20exame%20'${filteredData?.description}'.`;
  const handleChange = (event: any) => {
    setName(event.target.value);
  };
  /*   const filteredData = id && data.filter((dat) => dat.name.toLowerCase().includes(id.toLocaleLowerCase().replace('-', ' '))) */

  useEffect(() => {
    async function loadData() {
      try {
        const response = await api.get(`geral/${id}`);
        setFilteredData(response.data);
      } catch (error) {
        throw error;
      }
    }

    loadData();
  }, [id]);

  return (
    <div className="container-page--product">
      {filteredData && <Head title={filteredData.title} />}
      <div className="left-side">
        <div className="info-product">
          <h1>
            Agendar <span>{filteredData && filteredData.description}</span> em
            Goiânia
          </h1>
          <div className="bradcumb">
            <Link to="/">Início</Link>
            <ChevronRightIcon />
            {filteredData && (
              <Link to={"/agendamentos/" + filteredData.type}>Páginas</Link>
            )}
            <ChevronRightIcon />
            {filteredData && (
              <Link
                to={`/${filteredData.type}/` + filteredData.description}
                id="link"
              >
                {filteredData.description}
              </Link>
            )}
          </div>
        </div>

        <div className="cta-info">
          {/*  <div className="img-cta">
            <AdsClickIcon />
          </div>
 */}
          <p>{filteredData && filteredData.description1}</p>

          <Button internalLink="#agendar" />
        </div>

        <div className="box-container--cta">
          <div className="box-cta">
            <AdsClickIcon />
            <div className="info-box">
              <h1>Valores acessíveis</h1>
              <p>Consultas e exames com até 60% de desconto.</p>
            </div>
          </div>

          <div className="box-cta">
            <ElectricBoltIcon />
            <div className="info-box">
              <h1>Agendamento Rápido</h1>
              <p>Agendar é super símples, fácil e rápido.</p>
            </div>
          </div>

          <div className="box-cta">
            <GpsFixedIcon />
            <div className="info-box">
              <h1>Rede particular próxima</h1>
              <p>Agendamento na rede particular mais próxima a você.</p>
            </div>
          </div>
        </div>

        <p className={active ? "text active" : "text"}>
          {filteredData && filteredData.description2}
          <br />
          <br />
          {filteredData && filteredData.description3}
        </p>
        {active ? (
          <h3 onClick={() => setActive(!active)}>Ler menos</h3>
        ) : (
          <h3 onClick={() => setActive(!active)}>Continuar lendo</h3>
        )}
        <div className="container-img">
          <img src={bannerImg} alt="persons" />
        </div>
      </div>

      <div className="right-side" id="agendar">
        <div className="box">
          <div className="particular-info">
            <h1>Rede particular</h1>
            <h3>Agende agora com até 60% de desconto</h3>
            <form>
              <input
                type="text"
                placeholder="Seu nome"
                value={name}
                onChange={handleChange}
              />
            </form>
            <Button link={link} />
          </div>
        </div>
      </div>
    </div>
  );
};
