import { Link } from "react-router-dom"

// MUI Icons
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

// Styles
import './styles.css';

export const PrivacyPolicy = () => {
    return (
        <div className="aboutus-container">
            <div className="info-about">
                <h1>Politica de Privacidade</h1>
                <div className="crumb">
                    <Link to="/">Ínicio</Link>
                    <ChevronRightIcon />
                    <Link to="/about" id="active">Politica de privacidade</Link>
                </div>
            </div>
        
        <p >Política de Cookies 33Doctor</p> 
 
        <p>Política de Privacidade e Informações sobre o tratamento Dados Pessoais pela  33Doctor </p>
        
        <p>Introdução:</p>
         
        <p> 
          A proteção da privacidade e do uso legal de dados pessoais é
         um dos pilares da 33Doctor <strong> (nós)</strong>, que tem como compromisso a garantia
         da segurança dos dados e da privacidade de nossos usuários. Esta
         política de privacidade explica como seus dados pessoais são coletados,
         usados, armazenados e divulgados por nós através de nossa plataforma,
         sistemas e aplicativo móvel (coletivamente, os <strong>Serviços</strong>. A 33Doctor
         poderá alterar a presente política, em particular se, devido a quaisquer
         alterações em nossas operações ou nas leis e regulamentos aplicáveis.
         </p>
 
 
         <p> Seções específicas do usuário </p>
         
         <p>I. SE VOCÊ É UM PACIENTE (USUÁRIO NÃO
         PROFISSIONAL DA NOSSA PLATAFORMA) </p>
         
         <p> 
         Se você é um paciente, ou um usuário
         de nossa plataforma em busca de informações de um profissional de saúde
         para agendamento, informamos a forma, propósitos de tratamento e uso de
         seus dados: 
         </p>
         
         <p>  A) Como obtemos seus dados pessoais; para que propósitos, e
         em que base legal, realizamos o seu processamento. </p>
         
         <p>  1. Se cadastrando para o uso de nossos serviços </p>
         
         <p> 
         Nós obtemos seus dados pessoais, através
         de seu cadastro realizado no ato de seu registro ou uso de nossos
         serviços. Quando você se registra em nossa plataforma ou aplicativo,
         pedimos que você forneça dados básicos, incluindo seu endereço de
         e-mail. Você também pode se registrar usando plataformas de mídia social
         como Facebook ou Google: nesse caso, será solicitado a permitir que
         esses provedores de terceiros compartilhem algumas de suas informações
         pessoais (como indicado na página de registro). Você também poderá
         adicionar informações sobre si mesmo, como seu nome, sobrenome, sexo,
         número de telefone, endereço, CPF e outros. Armazenaremos estas
         informações para permitir que você use os Serviços. <br />O uso de nossos
         Serviços pode se dar por meio de nosso site ou aplicativo móvel. Nossos
         serviços permitem: agendamento de consulta com especialistas; conversar
         com especialistas online, enviar mensagens para especialistas e avaliar
         sua experiência. Você também pode verificar o histórico de suas visitas
         e gerenciar sua conta de usuário em nossa plataforma e aplicativo móvel.
         </p>
 
         <p> 
         Em sua conta de usuário no celular, você pode gerenciar notificações
         push (por exemplo: pop-ups) e outras notificações semelhantes do
         sistema. 
         </p>
        
         <p> 
         Quando você se registra para utilizar nossos serviços, você
         entra em um contrato legalmente vinculativo conosco, sendo a base legal
         utilizada para o processamento de seus dados pessoais, em acordo com o
         artigo 7º V da LGPD. 
         </p>
         
         <p> 2. Agendar uma consulta com um profissional de saúde ou um exame médico </p>
         
         <p> 
         Quando você agenda uma consulta com um
         profissional de saúde ou pessoa jurídica que preste serviços de saúde,
         em nosso site ou aplicativo móvel, obtemos informações adicionais de
         você. Essas informações podem incluir:
         </p>
 
         
         - Nome e sobrenomes, <br />
         - Número de telefone, <br />
         - Endereço de e-mail, <br />
         - Endereço residencial <br />
         - CPF <br />
         - Data e hora da consulta, <br />
         - Motivo da sua consulta, os exames ou testes que você está agendando.<br /> 
         - Tipo de exame médico,<br />
         - Quaisquer informações
         adicionais que tenha adicionado (por exemplo, questionários, detalhes
         adicionais) durante o processo de agendamento de consultas.<br />
         
         <p>
         Tais dados
         serão armazenados em nossa plataforma e transferidos ao profissional de
         saúde, e/ou para a clínica que emprega o especialista. Uma vez que seus
         dados pessoais são transferidos para o profissional de saúde ou
         estabelecimento, estes se tornam, em acordo com a legislação, um
         controlador de dados que processará seus dados pessoais para seus
         próprios fins (por exemplo, em especial para fins de prestação de
         serviços de saúde) em acordo com seus próprios termos e políticas.
         Quando você agenda uma visita em nossa plataforma, podemos processar (ou
         seja, armazenar e compartilhar com o profissional de saúde ou
         estabelecimento) dados considerados sensíveis, (em particular, dados de
         saúde como: especialidade do profissional ou clínica; o motivo de sua
         visita; histórico de agendamento), processando tais dados com base no
         exercício regular de direito advindo do contrato que você firma ao
         utilizar nossos serviços para que o profissional de saúde possa
         fornecer-lhe nossos serviços técnicos. Sem esse consentimento, não
         seríamos capazes de fornecer-lhe a nossa reserva e serviços técnicos. O
         processamento de seus dados pessoais relacionados à saúde com base no
         exercício regular de direito contratual, está em acordo com o artigo 11
         II d da LGPD. <br />
         Após o agendamento e o envio de seus dados ao profissional
         ou estabelecimento de saúde, o processamento de seus dados será regido
         em acordo com as políticas dos mesmos. Ao usar nossa plataforma, você
         pode trocar mensagens e/ou conversar com um profissional de saúde: A
         33Doctor não tem acesso às informações que você forneceu ao profissional
         de saúde durante o bate-papo ou conversa, pois esses dados são
         criptografados de ponta a ponta. Você pode navegar pelo histórico de
         perguntas e respostas. </p>
 
         <p>
         3. Como seus dados pessoais são armazenados e
         como são compartilhados com um especialista 
         </p>
 
         <p>
         Como parte dos nossos
         serviços, permitimos que você opte por armazenar seus dados pessoais de
         saúde em sua conta de Usuário do Aplicativo. Esta funcionalidade, entre
         outras, te dá a possibilidade de: Ter as informações de saúde que você
         considera convenientes armazenadas em um único local. Compartilhar essas
         informações com o profissional de saúde que você agendar uma consulta
         para que esse profissional de saúde tenha essas informações antes da
         consulta. Os dados serão processados ​​por nós com o seu consentimento
         com o único propósito de permitir que você armazene esses dados e para
         compartilhá-los com os profissionais de saúde que você decidir.
         </p>
 
         <p>Categorias de dados: </p>
 
         <p>
         Você terá que fornecer esses dados para ativar a
         funcionalidade: Nome, sobrenome e e-mail. 
         </p>
 
         <p>
         Além disso, se você decidir
         adicionar esses dados, coletaremos também: seu número de telefone, seu
         documento de identidade, plano de saúde (se houver), alergias, histórico
         médico, histórico familiar, medicamentos tomados, consultas anteriores.
         Para maior clareza, só processaremos dados de saúde se você decidir
         adicioná-los. Você é livre para adicionar os dados que quiser, não há
         dados mínimos ou obrigatórios nesse caso. 
         </p>
 
         
         <p>Base jurídica:</p>
 
         <p>
         Apenas processaremos esses dados com base no seu consentimento prévio. O
         consentimento pode ser retirado a qualquer momento. Se você revogar seu
         consentimento, excluiremos seus dados de saúde. Os demais dados serão
         excluídos de acordo com os períodos de retenção indicados nesta
         política.<br/> Apenas compartilharemos os seus dados com os profissionais de
         saúde com o seu consentimento prévio e apenas com os profissionais de
         saúde que você decida que devem ter acesso ao seu Perfil de Saúde.
         Observe que os profissionais de saúde atuarão como controladores de
         dados independentes e processarão seus dados de acordo com suas próprias
         políticas de privacidade. 
         </p>
 
         <p>4. Adicionando comentário e avaliações </p>
         
         <p>
         Quando você adiciona uma avaliação sobre um profissional ou estabelecimento de
         saúde em nosso site, obtemos seus dados pessoais (por exemplo, seu nome,
         motivo da sua visita e outros). O processamento de seus dados pessoais
         relacionados à saúde com base no exercício regular de direito advindo do
         contrato firmado para prestarmos os serviços de agendamento junto aos
         profissionais de saúde, baseiam-se no artigo11 II d da LGPD.<br /> Por favor,
         esteja ciente de que seus comentários e avaliações serão públicos, sendo
         que você é o único responsável pelo conteúdo exposto, sendo que caso
         inclua qualquer informação de caráter privado ou similar elas estarão
         disponíveis para todos os usuários da plataforma. 
         </p>
         
         <p>5. Marketing </p>
 
         <p>
         Quando você optar por receber comunicações comerciais e de marketing, através
         do preenchimento dos seus dados no cadastro, podemos processar seu
         e-mail, número de telefone, nome e sobrenome para este fim. Você pode
         optar por não receber essas comunicações a qualquer momento. A base
         legal para o processamento de seus dados pessoais para este fim é o
         artigo 7 IX da LGPD. 
         </p>
 
         <p>6. Outros propósitos</p> 
         
         <p>
         Quando você usa nossos
         Serviços, podemos obter dados adicionais, incluindo, por exemplo:
         informações sobre seu dispositivo, endereço IP, fuso horário e idioma ou
         navegador. Também temos acesso às informações sobre quando você usou
         nossos Serviços pela primeira e última vez e tempo de uso.<br />Ao usar um
         aplicativo móvel, também podemos obter suas informações de localização,
         com base em dados gps. Você sempre pode desligar suas informações de
         localização. <br /> Vamos processar esses dados pessoais para: 
         </p>
     
         <p>
         <li> processar
         reclamações em relação aos serviços prestados, para defesa contra eles
         ou contra reclamações de terceiros relacionadas ao uso dos Serviços;
         </li>
         <li>
         para informá-lo sobre novos recursos e funcionalidades de nossos
         Serviços, 
         </li>
         <li>
         gerenciar e planejar nossas atividades de negócios (por
         exemplo, analisar como você usa nossos produtos e serviços e prever a
         demanda por nosso produto, ou prever a maneira como nossos usuários
         usarão nossos Serviços no futuro e estimar as tendências nas
         necessidades e preferências dos usuários). Algumas dessas informações
         podem ser anonimizadas (por exemplo, a maneira como você navega em nosso
         site), mas algumas das informações, juntamente com outras informações
         (por exemplo, por quanto tempo você estava logado em nossa plataforma e
         qual endereço de e-mail você usou) pode revelar sua identidade.
         </li>
         </p>
 
         <p>
         Processamos essas informações com base em nossos interesses legítimos, o
         que constitui um fundamento legal para o processamento de dados pessoais
         nos termos do artigo 7 IX da LGPD.
         </p>
 
         <p> 
         B) Processamos informações ou dados
         relacionados à sua saúde? 
         </p> 
 
         <p>
         Se você agendar uma consulta ou um exame com
         um profissional ou estabelecimento de saúde, adicionar comentários ou
         avaliação, conversar com um profissional de saúde, poderemos ter acesso
         aos dados de sua saúde que venham a ser incluídos pelo profissional em
         nossos sistemas. Podemos atuar como operador de dados em nome de
         especialistas e clínicas que nos confiam seus dados pessoais
         relacionados à saúde de seus pacientes. Para obter mais detalhes,
         consulte a seção: Como obtemos seus dados pessoais; para que
         propósitos, e em que base legal, processá-lo, acima; e para a seção
         Atuamos como um operador de dados em nome de especialistas e clínicas?
         abaixo. 
         </p>
 
         <p> 
         C) Você pode nos fornecer dados pessoais de outras pessoas? 
         </p> 
 
         <p>
         Se você agendar uma consulta em nome de outra pessoa (exemplo, reservar uma
         visita para seu membro da família) podemos obter os dados pessoais dessa
         pessoa. Processaremos esses dados pessoais para os mesmos propósitos que
         processamos seus dados pessoais. 
         </p>
 
         <p> 
         D) Atuamos como um operador de dados em
         nome de especialistas e clínicas? 
         </p> 
 
         <p>
         Prestamos diferentes serviços a
         profissionais e estabelecimentos de saúde, tais serviços permitem o
         carregamento e armazenagem de dados pessoais dos pacientes, informações
         sobre agendamento, e informações relacionadas à saúde. Os serviços
         permitem o envio de mensagens de po ou e-mails aos pacientes para
         gerenciar os agendamentos, ou para o envio de campanhas de informação
         aos pacientes. Em tais casos, atuamos como operador de dados (art. 5 VII
         LGPD) e processamos os dados pessoais de seus pacientes. Quando atuamos
         como um operador de dados, processamos dados pessoais apenas sob as
         instruções dos profissionais ou estabelecimentos de saúde para quem
         prestamos serviços. Não processamos esses dados para nossos próprios
         propósitos. Isso também se aplica às mensagens enviadas através de nossa
         plataforma, mensagens de po, e-mails ou comunicações ou campanhas
         semelhantes: sendo o conteúdo de responsabilidade exclusiva dos
         profissionais e estabelecimentos de saúde. <br /> Se você não quiser receber
         tais mensagens, entre em contato com o profissional ou estabelecimento
         de saúde que lhe enviou a mensagem diretamente.
         </p>
         
         <p> 
          II. SE VOCÊ ESTÁ
         REGISTRADO CONOSCO COMO UM ESPECIALISTA 
         </p>
 
         <p>
         Se você é um profissional de
         saúde usando nossa plataforma e nossos Serviços, e você(i)registrou e/ou
         criou um perfil na plataforma, (ii) firmou um contrato de prestação de
         Serviços, ou (iii) trabalha, para ou com um estabelecimento de saúde que
         firmou um contrato de prestação de Serviços, então o seguinte se aplica
         a você:
         </p> 
 
         <p> 
         A) Como obtemos seus dados pessoais. Propósitos e base legal do
         processamento 
         </p>
         
         <p> 
         1. Cadastramento e utilização de nossos serviços 
         </p>
         
         
         <p>
         Nós obtemos seus dados pessoais diretamente de você quando você se registra
         conosco ou usa nossos serviços. Quando você se cadastrar para uso de
         nossos Serviços, solicitamos dados relacionados às suas atividades
         profissionais e outras informações sobre você que serão publicadas em
         seu perfil em nossa plataforma. Em particular, você pode nos fornecer as
         seguintes informações: 
         </p>
         
         - seu nome e sobrenome, <br />
         - seu endereço profissional, <br />
         - seu endereço de e-mail, <br />
         - sua especialização, sua educação e informações sobre doenças que você trata ou exames que você realiza, <br />
         - seu número profissional (o número de registro em seu conselho profissional que permite que você realize atividades profissionais), <br />
         -sua imagem, <br />
         - detalhes das clínicas nas quais você presta serviços, e <br />
         - Quaisquer outros dados que você nos fornecer durante o processo de
         registro ou durante a execução de um contrato pago. <br />
         
         
         <p>
         Se você contratou o
         pacote Premium, SaaS e/ou qualquer outro serviço semelhante, habilitou
         agenda ou telemedicina em nossa plataforma, você nos fornecerá detalhes
         sobre sua programação, informações sobre preços de seus serviços e
         métodos de pagamento que você aceita.
         </p>
 
         <p>
          Quando você contrata nossos
         Serviços, você entra em um contrato conosco. O tratamento de seus dados
         pessoais se dará para a execução deste contrato, sendo fundamento legal
         válido para o processamento (artigo 7, V LGPD). Também processamos seus
         dados pessoais com base em nossas obrigações legais, por exemplo, a fim
         de emitir notas fiscais e manter nossos registros financeiros
         atualizados em acordo com o artigo 7º II e X da LGPD. 
         </p>
 
         <p>
         Também podemos
         obter seus dados pessoais de estabelecimento de saúde que firmaram um
         contrato conosco. Tais estabelecimentos podem, sob sua responsabilidade,
         transferir seus dados pessoais para nós e para executar o contrato que
         temos podemos realizar o tratamento de seus dados. O processamento de
         seus dados pessoais é essencial para nós, no entanto você sempre pode
         entrar em contato com o estabelecimento que transferiu seus dados
         pessoais para nós e se requerer a retirada de suas informações pessoais.
         </p>
 
          <p> 2. Propósitos de marketing  </p>
 
          
         <p>
          Quando você optar por receber comunicações
         comerciais e de marketing, podemos processar seu e-mail, número de
         telefone, nome e sobrenome para este fim. Você pode optar por não
         receber essas comunicações a qualquer momento. O fundamento legal para o
         processamento de seus dados pessoais para este fim é o artigo 7 IX da
         LGPD. 
         </p>
 
         <p>3. Outros propósitos </p>
 
         <p>
         Quando você usa nossos Serviços, podemos
         obter dados adicionais, incluindo, por exemplo: informações sobre seu
         dispositivo, endereço IP, fuso horário e idioma ou navegador que você
         usa. Também obtemos informações sobre quando você usou nossos Serviços
         pela primeira, última vez e tempo de uso. 
         </p>
        
         <p>
         Ao usar um aplicativo móvel,
         também podemos obter suas informações de localização, com base em dados
         gps. Você sempre pode desligar suas informações de localização.
         </p> 
         <p>
         <li>
         Vamos processar esses dados pessoais em ordem: processar reclamações em
         relação aos serviços prestados, para defesa contra eles ou contra
         reclamações de terceiros relacionadas ao uso dos Serviços; 
         </li>
         
         <li>Para informá-lo sobre novos recursos e funcionalidades de nossos Serviços,</li>
         <li>
         Gerenciar e planejar nossas atividades de negócios (por exemplo,
         analisar como você usa nossos produtos e serviços e prever a demanda por
         nosso produto, ou prever a maneira como nossos usuários usarão nossos
         Serviços no futuro e estimar as tendências nas necessidades e
         preferências dos usuários). Algumas dessas informações podem ser
         anonimizadas (por exemplo, a maneira como você navega em nosso site),
         mas algumas das informações, juntamente com outras informações (por
         exemplo, por quanto tempo você estava logado em nossa plataforma e qual
         endereço de e-mail você usou) podem revelar sua identidade.
         </li>
         </p> 
         
         <p>
          Processamos
         essas informações com base em nossos interesses legítimos, o que
         constitui um fundamento legal para o processamento de dados pessoais nos
         termos do artigo 7 IX da LGPD .       
          </p>
 
          <p> III. SE VOCÊ É UM PROFISSIONAL DE SAÚDE
         NÃO REGISTRADO  </p> 
 
         <p>
         Se você é um especialista, cujo nome e detalhes
         profissionais aparecem em nossa plataforma, mas você não se cadastrou
         (ou seja, criou uma conta ou perfil) conosco e não contratou os nossos
         Serviços, então o seguinte se aplica a você: 
         </p> 
 
         <p>A) Como obtemos seus dados
         pessoais. Para que propósitos e em que base legal processá-lo 
         </p>
 
         <p>
         A 33Doctor
         obteve seus dados pessoais de fontes disponíveis publicamente, por
         exemplo: 
         </p>
 
         - registros publicados por você, <br />
         - registros de médicos ou
         registros profissionais similares,  <br />
         - base pública de seu conselho
         profissional,  <br />
         - ou dos usuários (pacientes) da 33Doctor que incluíram 
         sobre sua experiência com sua prática.  <br />
         <p>Processamos seus dados pessoais para: </p>
         - disponibilizar seus dados pessoais em nossa plataforma, a fim de
         informar nossos usuários de suas atividades profissionais, e/ou <br />
         - permitir que seus pacientes que também são usuários de nossos Serviços
         comentem e avaliem sua experiência de agendamento e de consulta  <br />
         
         <p>
         Se você entrar em contato conosco para receber informações sobre nós ou nossos
         Serviços, também podemos usar seus dados pessoais para enviar-lhe
         comunicações comerciais sobre nossos Serviços. Lembre-se, você pode
         optar, a qualquer momento, por não receber mais contato.
         </p>
 
                
         <p>
          A 33Doctor
         processa seus dados pessoais com base em seus interesses legítimos
         previstos no art. 7 IX da LGPD. 
         </p>
 
         <p>
         A 33Doctor visa garantir que seus
         interesses ou direitos e liberdades fundamentais não sejam sobrepostos
         aos nossos interesses legítimos de processamento de seus dados pessoais,
         assegurando-se de que você pode se opor ao processamento de seus dados
         pessoais requerendo sua exclusão, que será realizada por nós. 
         </p>
         
          <p>
           B) Quais dados pessoais processamos?
           </p>
           
           <p>Processamos os seguintes dados pessoais: </p>
             - nome(s) e sobrenomes(s), < br />
             - seu endereço profissional, < br />
             - sua profissão e/ou especialidade, < br />
             - opiniões dos usuários dos Serviços. < br />
             <p>Também podemos processar seu número de registro profissional.</p>
 
             <p> 
         INFORMAÇÕES APLICÁVEIS A
         TODOS OS USUÁRIOS 
         </p>
         <p> Compartilhamos seus dados pessoais com terceiros?  </p>
 
         <p>
         Podemos divulgar seus dados pessoais para outras empresas que fazem
         parte do grupo 33Doctor, apenas para fins de prestação de serviços
         para você. 
         </p>
 
         <p>
         Também podemos compartilhar seus dados pessoais com
         provedores de terceiros, novamente apenas com o propósito de fornecer
         nossos serviços a você. Os terceiros podem atuar como operadores de
         dados e firmaram contratos garantindo a proteção de seus dados pessoais,
         ou atuarão como controladores para a prestação de seus serviços
         </p>
 
         <p>
         Por exemplo, podemos compartilhar seus dados pessoais com as seguintes
         categorias de operadores de dados:
         </p> 
         - provedores de hospedagem em nuvem e manutenção de servidores, <br />
         - ferramentas de comunicação, <br />
         - ferramentas de suporte ao cliente, <br />
         - consultores externos, auditores ou conselheiros, <br />
         - prestadores de serviços de pagamento, bancos, agências de referência de crédito e prevenção de fraudes e companhias de seguros, <br />
         - Empresas de TI que nos fornecem software e serviços similares. <br />
         - Empresas que realizam a autorização de consultas e procedimentos por meio das operadoras de  plano de saúde. <br />
         - Empresas que permitem a emissão de receitas ou prescrições eletrônicas. <br />
         
         <p>
         Finalmente, podemos divulgar dados a fim de
         atender requisitos legais, fazer cumprir nossas políticas, fazer contato
         com autoridades judiciais ou regulamentares, quando exigido pela lei
         aplicável, e proteger nossos direitos e propriedade. Também podemos
         compartilhar suas informações pessoais com outras entidades
         empresariais, caso planejemos nos fundir, receber investimentos ou ser
         adquiridos por uma entidade empresarial.
         </p>  
 
         <p>
         Não transferiremos suas
         informações pessoais para terceiros, a menos que você nos dê seu
         consentimento prévio, salvo quando o fizermos para prestar nossos
         serviços com base legal na execução de nosso contrato. 
         </p>
 
         <p>Transferimos seus dados pessoais internacionalmente? </p>
         
         <p>
         Alguns de nossos provedores
         (operadores de dados) estão baseados fora do Brasil, contudo para que
         possamos transferir seus dados pessoais para países terceiros, sempre
         garantimos que essas transferências atendam aos requisitos de segurança
         da LGPD e da GDPR.
         </p>
 
        <p> Tempo de processamento de seus dados pessoais:  </p>
        
        <p>
         Nós apenas manteremos as informações que coletamos sobre você pelo tempo
         necessário para os propósitos acima estabelecidos, ou conforme
         necessário para cumprir quaisquer obrigações legais às quais estamos
         sujeitos. O período para o qual reteremos informações sobre você vai
         variar dependendo do tipo de informação e dos propósitos para os quais
         as utilizamos. Em geral, manteremos nossos registros por até 5 anos
         depois que você terminar seu relacionamento conosco, a fim de cumprir
         com nossas obrigações legais. Para obter mais detalhes, consulte a
         tabela a seguir: 
         </p>
 
      
 
         <p> Quais são seus direitos em relação ao processamento de seus dados pessoais </p>
 
         <p>Você tem os seguintes direitos sob LGPD:</p>  
         
         <li>
         você tem o direito de ser informado sobre o
         processamento de seus dados pessoais (ou seja, para que fins, que tipo
         de dados pessoais, para quais destinatários são divulgados, períodos de
         armazenamento, quaisquer fontes de terceiros a partir dele foi obtido,
         confirmação de se automatizamos a tomada de decisão, incluindo perfil, e
         a significância, e consequências previstas). Ler esta Política de
         Privacidade faz parte do seu direito de ser informado, 
         </li>
         <li>
         você tem o direito de apresentar uma reclamação junto a 33Doctor no email
         comercial@33doctor.com.br, ou em nosso endereço postal, ou junto a
         autoridade nacional de proteção de dados, se você considerar que
         processamos seus dados pessoais ilegalmente, 
         </li>
         <li>
         você tem o direito de solicitar uma cópia dos dados pessoais que processamos sobre você, 
         </li>
         <li>
         você pode pedir a exclusão de seus dados (se você considerar que não temos o
         direito de segurá-lo) ou a correção (se você acha que é impreciso) de
         seus dados pessoais, 
         </li>
         <li>
         você pode se opor ao processamento de seus dados
         pessoais ou (quando dependemos do seu consentimento para processamento)
         para retirar seu consentimento, 
         </li>
         <li>
         você tem o direito de restringir o processamento de seus dados pessoais e/ou
         </li>
         <li>
         você pode requerer a portabilidade de seus dados.
         </li>
    
         <p>
         Você pode entrar em contato conosco (veja
         os detalhes de contato na parte inferior desta política) se quiser fazer
         cumprir qualquer um desses direitos. Cumpriremos nossas obrigações
         legais no que diz respeito aos seus direitos como um sujeito de dados.
         Qualquer solicitação de acesso aos seus dados pessoais deve ser
         realizada por escrito e nos esforçaremos para responder dentro de um
         período razoável e em qualquer caso dentro de um mês (ou o prazo
         diferente que comunicaremos prontamente a você em caso de solicitações
         complexas ou numerosas). Reservamo-nos o direito de nos recusarmos a
         responder quando os pedidos forem manifestamente infundados ou
         excessivos: neste caso, explicaremos a situação e informaremos você
         sobre seus direitos. Nosso objetivo é garantir que as informações que
         temos sobre você sejam precisas o tempo todo. Para nos ajudar a garantir
         que suas informações estão atualizadas, você precisará nos informar se
         algum de seus dados pessoais mudar. Mediante sua solicitação, tomaremos
         medidas razoáveis para garantir que os dados sejam precisos, e
         corrigiremos quaisquer dados pessoais que estejam incorretos e em
         qualquer caso dentro de um mês de sua solicitação.
         </p>
 
         <p>Realizamos atividades de criação de perfil? </p>
         
         <p>
         Nenhuma decisão está sendo tomada apenas com base
         na tomada de decisões automatizadas. Não usamos nenhum sistema de
         criação de perfil ou ferramentas para processar seus dados.
         </p>
 
          
         <p>Links para outros sites </p>
         
         <p>
         Nosso site ou aplicativo pode conter links para outros
         sites, inclusive através de botões de mídia social. Embora tentemos
         vincular apenas a sites que compartilham nossos altos padrões e respeito
         à privacidade, não somos responsáveis pelas práticas de conteúdo,
         segurança ou privacidade empregadas por outros sites e um link não
         constitui um endosso deste site. Uma vez que você utilize outro site por
         meio de nosso site ou aplicativo, você está sujeito aos termos e
         condições daquele site, incluindo, mas não se limitando, a sua política
         e práticas de privacidade na internet. Por favor, verifique essas
         políticas antes de enviar qualquer dado pessoal.
         </p>
    
          
         <p>Entre em contato conosco:</p> 
         
         <p>
         Se você tiver alguma dúvida sobre seus dados pessoais, você
         pode entrar em contato conosco através dos nossos canais de comunicação,
         por email: comercial@33doctor.com.br ou por correspondência para o nosso
         endereço. 
         </p>
 
         <p>
         Política de Cookies 33Doctor 
         </p> 
 
         <p>
         Esta página descreve quais informações coletamos, como as usamos e por que às vezes precisamos
         armazenar cookies. Também compartilharemos como você pode impedir que
         esses cookies sejam armazenados, no entanto, isso pode rebaixar ou
         quebrar certos elementos da funcionalidade do site.
         </p>
 
         <p>O que são cookies? </p>
 
         <p>
         Cookies são pequenos arquivos que são baixados em seu computador ou
         outro dispositivo que use para navegar em nosso site. A maioria dos
         sites usam cookies. Normalmente, um cookie inclui as seguintes
         informações: o nome do site de onde ele veio; quanto tempo o cookie
         ficará no seu computador ou dispositivo; e um valor (geralmente um
         número único gerado aleatoriamente). Alguns cookies podem incluir dados
         adicionais, em particular relacionados ao seu fuso horário ou idioma
         usado para navegação.
         </p>
 
         <p>Que tipo de cookies usamos?</p>
         
         <p>
         Alguns cookies estão sempre ligados quando você nos visita, e você não pode desligá-los a
         menos que você altere as configurações do seu navegador. Chamamos esses
         de cookies necessários. Nós os usamos para garantir que nossos serviços
         digitais funcionem corretamente e permitam o uso de funcionalidades,
         como login do usuário e gerenciamento de contas.
         </p>
         
         <p>
         Também usamos cookies funcionais para tornar sua experiência mais amigável, em particular para
         lembrar e armazenar suas informações em nossos sites e personalizar o
         conteúdo de nossos serviços. 
         </p>
 
         <p>
         Também usamos cookies de desempenho para
         observar como nossos serviços são usados e obter estatísticas do uso.
         </p>
 
         <p>
         Também usamos cookies de terceiros. Esses cookies podem rastrear como
         você usa sites diferentes, incluindo o nosso. Por exemplo, você pode
         obter os cookies de uma empresa de mídia social quando você registrou em
         nosso site usando um plugin de mídia social. Caso deseje, você pode
         desativar esses cookies.
         </p>
 
         <p>
         Cookies de marketing usados para publicidade
         direcionada. Caso deseje, você pode desativar esses cookies. 
         </p>
 
        <p> Como usamos Cookies? </p>
 
        <p>
         Usamos cookies por uma variedade de razões detalhadas abaixo.
         Para aproveitar melhor o nosso site, recomendamos que você configure seu
         dispositivo para aceitar todos os cookies.  No entanto, usando as
         configurações do seu dispositivo, você pode desativar ou limitar certos
         tipos de cookies.
         </p>
 
         <li>
           Fornecer-lhe uma melhor experiência: Os cookies são
         usados para uma série de coisas que ajudam a melhorar sua experiência
         online. Eles podem ajudar de diferentes maneiras, incluindo, mas não
         limitado à: garantir que nossos sites carreguem mais rápido, mantê-lo
         conectado, mesmo em diferentes dispositivos, tornando os logins mais
         rápidos e acessíveis lembrando seus dados, lembrando a maneira como você
         usou nosso serviço anteriormente, permitindo que você agende uma
         consulta com um médico de forma mais rápida e acessível, ou
         certificando-se de que nossas páginas são otimizadas para o seu
         navegador ou dispositivo.
         </li> 
 
         <li>
         Ajuste nossos serviços às suas preferências:
         Os cookies podem nos ajudar a apresentar-lhe conteúdo que atenda aos
         seus interesses e preferências. Os cookies também nos ajudam a
         personalizar nosso site para você.
         </li>
 
         <li>
         Aprimore a segurança: Usamos alguns
         cookies para garantir a segurança de nossa plataforma. 
         </li>
         
         <li>
         Melhorando nossos sites: Usamos cookies para melhorar nossos serviços e para prever a
         maneira como os usuários usam nossos sites e torna-lo melhor para nossos
         usuários. 
         </li>
 
         <li>
         Marketing usamos alguns cookies para fornecer anúncios de
         nossos serviços. Usamos esses cookies em cooperação com nossos parceiros
         de marketing (Google e Facebook). 
         </li>
 
         <p> Cookies de terceiros </p>
        
         <p>
         Usamos Cookies de Terceiros para perseguir nossos interesses legítimos e tornar nossos
         sites melhores para nossos usuários. 
         </p>
 
         <li>
         Usamos o Google Analytics. O Google
         Analytics é amplamente utilizado por sites em todo o mundo. Esta
         ferramenta nos ajuda a entender, prever e ajustar nossos sites para
         nossos usuários e nos ajuda a gerenciar nossas bases de dados e melhorar
         nosso SEO.
         </li>
 
         <li>
         Usamos cookies do nosso parceiro HubSpot para lhe apresentar
         informações relevantes sobre nossos serviços e nos ajudar a saber se o
         conteúdo que você fornece é interessante para você. 
         </li>
       
         <li>
         Também usamos cookies de terceiros para medir o uso do nosso site, em particular esses
         cookies podem registrar quanto tempo você gasta em nosso site e como
         você navega.
         </li>
 
         <li>
         Também usamos cookies de terceiros para testar nossos novos
         recursos e produtos. Esses cookies nos ajudam a garantir que você receba
         uma experiência consistente de nossos serviços. 
         </li>
         
         <li>
         Também usamos botões de mídia social e/ou plugins neste site que permitem que você se conecte
         com sua rede social de várias maneiras. Para que estes trabalhem nos
         seguintes sites de mídia social, incluindo; Google, Facebook definirá
         cookies através do nosso site que podem ser usados para melhorar seu
         perfil em seu site ou contribuir para os dados que possuem para vários
         propósitos descritos em suas respectivas políticas de privacidade.
         </li>
 
         <li>
         Usamos soluções técnicas fornecidas pelo Facebook e Google para melhor
         adaptar nossos serviços e oferecer-lhe uma melhor experiência ao
         usuário. 
         </li>
 
         <p>Quanto tempo armazenamos cookies? </p>
 
         
         <p>
         Dependendo do tipo de cookie,
         alguns deles são armazenados durante um curto período de tempo, enquanto
         outros são armazenados por períodos mais longos. Abaixo você encontrará
         informações mais detalhadas. 
         </p>
         
         <p>
         Cookies de acesso - esses cookies duram
         apenas durante um acesso e são excluídos automaticamente quando você
         fecha seu navegador. 
         </p>
 
         <p>
         Cookies persistentes - esses cookies permanecem
         também se o seu navegador foi fechado ou seu dispositivo foi desligado e
         está ativo por um período de tempo definido no cookie. Usamos cookies
         persistentes quando precisamos saber quem você é para mais de uma sessão
         de navegação. Por exemplo, usamos-nas para lembrar suas preferências
         para a próxima vez que você visitar ou caso tenha realizado o
         agendamento de uma consulta. 
         </p>
         
         <p>
         Desabilitando cookies Você pode impedir a
         configuração de cookies ajustando as configurações do seu navegador -
         você pode encontrar instruções sobre como fazê-lo na tabela abaixo.
         Esteja ciente de que a desativação de cookies afetará a funcionalidade
         deste e de muitos outros sites que você visitar. A desativação de
         cookies geralmente resultará em também desativar certas funcionalidades
         e recursos de nossos serviços. Portanto, recomenda-se que você não
         desabilite cookies para não prejudicar as funcionalidades. Caso deseje
         fazê-lo poderá gerenciá-los da seguinte forma: 
         </p> 
 
         <p>
         Navegador Link para configurações Como você pode gerenciar cookies Google Chrome: <br />
         <a  href="https://support.google.com/chrome/answer/95647?hl=en-GB&p=cpn_cookies" target='_blank' rel="noreferrer"> <strong>https://support.google.com/chrome/answer/95647?hl=en-GB&p=cpn_cookies</strong></a>
        </p> 
 
        <p>
         Você deve clicar em três pontos que estão no canto superior do lado
         direito e abrir configurações. No site de configuração clique em
         segurança e privacidade para gerenciar cookies. Safari: <br />
         <a href="https://support.apple.com/en-us/HT201265 " target='_blank' rel="noreferrer" ><strong>https://support.apple.com/en-us/HT201265</strong> </a>
         </p>
 
         <p>
         Você deve ir para a página de
         configurações preferências e clicar em segurança. Na seção
         segurança você pode gerenciar seus cookies. Microsoft Edge: <br />
         <a href="https://support.microsoft.com/en-us/search?query=enable%20cookies%20in%20edge" target='_blank' rel="noreferrer"> <strong>https://support.microsoft.com/en-us/search?query=enable%20cookies%20in%20edge</strong></a>
         </p>
 
         <p>
         Você deve clicar em três pontos que estão no canto superior do lado
         direito e abrir configurações. No site de configuração clique em
         privacidade, pesquisa e serviços para gerenciar cookies. Mozilla
         Firefox:<br />
         <a href="https://support.mozilla.org/en-US/kb/websites-say-cookies-are-blocked-unblock-them" target='_blank' rel="noreferrer"> <strong>https://support.mozilla.org/en-US/kb/websites-say-cookies-are-blocked-unblock-them</strong> </a>
         </p>
 
         <p>
         Você deve clicar em três barras que estão no canto superior do lado
         direito e abrir opções. No site de opções clique em segurança e
         privacidade para gerenciar cookies. Mais informações Esperamos que
         todas as informações fornecidas nestas informações sejam úteis para
         você.
         </p>
         
         <p>
         Se você quiser obter mais informações, você pode entrar em contato
         conosco através de nosso e-mail <strong>comercial@33doctor.com.br</strong>
         </p> 
            
        </div>
    )
}