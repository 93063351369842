import React from 'react';

type PropsHead = {
    title: string,
    description?: string,
}

export const Head = (props: PropsHead) => {
    const { title, description } = props;

    React.useEffect(() => {
        document.title = title;
    }, [title]);

    return (
        <>
            <link rel="shortcut icon" href="/assets/shortcut.png" type="shortcut" />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
        </>
    );
};